import { gql } from "@apollo/client";
import React, { useCallback } from "react";

import { useFetchDatabricksAccessTokenForSuccessViewMutation } from "./DatabricksOAuthSuccessView.generated.js";
import { OAuthSuccessView } from "./OAuthSuccessView.js";

gql`
  mutation FetchDatabricksAccessTokenForSuccessView(
    $code: String!
    $state: String!
  ) {
    fetchDatabricksAccessToken(code: $code, state: $state) {
      id
      accessTokenExpires
      linkedDataConnections {
        id
      }
    }
  }
`;

export const DatabricksOAuthSuccessView: React.FunctionComponent = React.memo(
  function DatabricksOAuthSuccessView() {
    const [fetchDatabricksAccessTokenMutation] =
      useFetchDatabricksAccessTokenForSuccessViewMutation();

    const fetchAccessToken = useCallback(
      async ({
        variables,
      }: {
        variables: {
          code: string;
          state: string;
        };
      }) => {
        const { data, errors } = await fetchDatabricksAccessTokenMutation({
          variables,
        });

        return {
          linkedDataConnectionIds:
            data?.fetchDatabricksAccessToken.linkedDataConnections.map(
              ({ id }) => id,
            ),
          errors: errors,
        };
      },
      [fetchDatabricksAccessTokenMutation],
    );

    return (
      <OAuthSuccessView
        broadcastChannel="databricks-oauth"
        fetchAccessToken={fetchAccessToken}
      />
    );
  },
);
