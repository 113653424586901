import { Card, Classes, Intent } from "@blueprintjs/core";
import { NoAttribution, OrgRole } from "@hex/common";
import React from "react";
import { Redirect } from "react-router";
import styled from "styled-components";

import { Heading } from "../components/Heading";
import {
  DocsIcon,
  ProjectIcon,
  UserIcon,
  WarningIcon,
  WorkspaceIcon,
} from "../components/icons/CustomIcons";
import {
  HexAnchorButton,
  HexNonIdealState,
  HexSpinner,
  HexSpinnerSizes,
} from "../hex-components";
import { useMeQuery } from "../route/HexRoutes.generated";
import { Routes, useQueryParams } from "../route/routes";
import HexLogo from "../static/integration/hex-logo.inline.svg";
import NotionLogo from "../static/integration/notion-logo.inline.svg";

const Container = styled.div`
  margin: 0 auto;
  padding: 20px;
`;

const AuthCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  padding: 40px;

  text-align: center;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;

  svg {
    width: 60px;
    height: 60px;
  }
  span {
    font-size: 48px;
  }
`;

const WorkspaceSummary = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    align-self: center;
  }
`;

const Title = styled(Heading)`
  width: 440px;
  margin-bottom: 20px;

  @media (max-width: ${({ theme }) => `${theme.mediaQuery.EXTRA_SMALL}px`}) {
    width: 100%;
  }
`;

const WorkspaceCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
`;

const WorkspaceHeading = styled(Heading)`
  display: flex;
  align-items: center;

  .${Classes.ICON} {
    color: ${({ theme }) => theme.fontColor.MUTED};
  }
`;

const PermissionSummary = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ul {
    margin-bottom: 0px;
    padding-left: 0px;

    list-style: none;
    li {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      margin-bottom: 8px;

      font-size: ${({ theme }) => theme.fontSize.LARGE};

      .${Classes.ICON} {
        color: ${({ theme }) => theme.fontColor.MUTED};
      }
    }
  }
`;

const SummaryTitle = styled(Heading)`
  text-align: center;
`;

const GrantButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const NotionUnfurlAuthorizeView: React.ComponentType = React.memo(
  function NotionUnfurlAuthorizeView() {
    const { data, error, loading } = useMeQuery();
    const loggedOut =
      !loading && (!data?.me || error || data.me.orgRole === OrgRole.ANONYMOUS);

    const queryParams = useQueryParams();
    const redirectTo = `${window.location.pathname}${window.location.search}${window.location.hash}`;

    if (loggedOut) {
      return (
        <Redirect
          to={Routes.LOGIN.getUrl({
            attribution: NoAttribution,
            params: { redirectTo },
          })}
        />
      );
    }

    if (!data && loading) {
      return <HexSpinner size={HexSpinnerSizes.LARGE} />;
    }

    if (!data || error || !data.me) {
      return (
        <HexNonIdealState
          description="There was an unexpected error. Please contact support@hex.tech for assistance."
          icon={<WarningIcon />}
          title="Something went wrong"
        />
      );
    }

    const decisionPath = `/api/v1/notion-unfurl/authorize`;
    const commonParams = {
      code: queryParams.get("code") ?? "",
      redirect_uri: queryParams.get("redirect_uri") ?? "",
      org_id: data.me.org.id,
      response_type: queryParams.get("response_type") ?? "",
      client_id: queryParams.get("client_id") ?? "",
      state: queryParams.get("state") ?? "",
    };

    const acceptParams = new URLSearchParams({
      ...commonParams,
      accepted: "true",
    });
    const acceptUrl = `${decisionPath}?${acceptParams.toString()}`;

    const denyParams = new URLSearchParams({
      ...commonParams,
      accepted: "false",
    });
    const denyUrl = `${decisionPath}?${denyParams.toString()}`;

    // TODO(notion-unfurl): ENG-3813
    return (
      <Container>
        <AuthCard>
          <LogoContainer>
            <NotionLogo />
            <span>🤝</span>
            <HexLogo />
          </LogoContainer>
          <WorkspaceSummary>
            <Title renderAs="h1">
              Notion is requesting permission to access your Hex workspace:
            </Title>

            <WorkspaceCard>
              <WorkspaceHeading renderAs="h3">
                <WorkspaceIcon
                  css={`
                    margin-right: 10px;
                  `}
                />
                {data.me.org.displayName}
              </WorkspaceHeading>
            </WorkspaceCard>
            <HexAnchorButton
              css={`
                margin-top: 10px;
              `}
              href={Routes.ORG_SELECTOR.getUrl({ params: { redirectTo } })}
              intent="primary"
              minimal={true}
            >
              Change workspace
            </HexAnchorButton>
          </WorkspaceSummary>
          <PermissionSummary>
            <SummaryTitle renderAs="h2">
              What will Notion be able to see?
            </SummaryTitle>
            <ul>
              <li>
                <UserIcon /> Your basic user info.
              </li>
              <li>
                <DocsIcon />
                Project titles and metadata for preview links.
              </li>
              <li>
                <ProjectIcon />
                Your project contents for embedded links.
              </li>
            </ul>
          </PermissionSummary>
          <GrantButtons>
            <HexAnchorButton
              css={`
                margin-right: 10px;
              `}
              href={denyUrl}
              large={true}
            >
              Cancel
            </HexAnchorButton>
            <HexAnchorButton
              href={acceptUrl}
              intent={Intent.PRIMARY}
              large={true}
            >
              Allow
            </HexAnchorButton>
          </GrantButtons>
        </AuthCard>
      </Container>
    );
  },
);
