import { gql } from "@apollo/client";
import React, { useCallback } from "react";

import { useFetchBigQueryAccessTokenForSuccessView } from "./BigQueryOAuthSuccessView.generated.js";
import { OAuthSuccessView } from "./OAuthSuccessView.js";

gql`
  mutation FetchBigQueryAccessTokenForSuccessView(
    $code: String!
    $state: String!
  ) {
    fetchBigQueryAccessToken(code: $code, state: $state) {
      id
      accessTokenExpires
      linkedDataConnections {
        id
      }
    }
  }
`;

export const BigQueryOAuthSuccessView: React.FunctionComponent = React.memo(
  function BigQueryOAuthSuccessView() {
    const [fetchBigQueryAccessToken] =
      useFetchBigQueryAccessTokenForSuccessView();

    const fetchAccessToken = useCallback(
      async ({
        variables,
      }: {
        variables: {
          code: string;
          state: string;
        };
      }) => {
        const { data, errors } = await fetchBigQueryAccessToken({
          variables,
        });

        return {
          linkedDataConnectionIds:
            data?.fetchBigQueryAccessToken.linkedDataConnections.map(
              ({ id }) => id,
            ),
          errors: errors,
        };
      },
      [fetchBigQueryAccessToken],
    );

    return (
      <OAuthSuccessView
        broadcastChannel="bigquery-oauth"
        fetchAccessToken={fetchAccessToken}
      />
    );
  },
);
