import {
  DataConnectionId,
  GraphQLErrorCode,
  getErrorMessagesFromErrors,
  getErrorMessagesWithCode,
} from "@hex/common";
import { BroadcastChannel } from "broadcast-channel";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import { useQueryParams } from "../route/routes";
import { GraphQLErrors } from "@apollo/client/errors";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export type OAuthCallbackBroadcastMessage = {
  success?: {
    linkedDataConnectionIds?: DataConnectionId[];
  };
};

export interface OAuthSuccessViewProps {
  fetchAccessToken: ({
    variables,
  }: {
    variables: {
      code: string;
      state: string;
    };
  }) => Promise<{
    linkedDataConnectionIds?: DataConnectionId[];
    errors?: GraphQLErrors;
  }>;
  broadcastChannel: string;
}

export const OAuthSuccessView: React.ComponentType<OAuthSuccessViewProps> =
  React.memo(function OAuthSuccessView({
    broadcastChannel,
    fetchAccessToken,
  }: OAuthSuccessViewProps) {
    const queryParams = useQueryParams();
    const code = queryParams.get("code");
    const state = queryParams.get("state");

    const [accessTokenResult, setAccessTokenResult] = useState<boolean | null>(
      null,
    );
    const [userFacingError, setUserFacingError] = useState<string | null>(null);

    const finishAuth = useCallback(async () => {
      if (code && state) {
        await fetchAccessToken({
          variables: { code, state },
        })
          .then(async ({ errors, linkedDataConnectionIds }) => {
            if (errors && errors.length > 0) {
              const userFacingErrors = getErrorMessagesFromErrors(
                errors,
                GraphQLErrorCode.USER_FACING_ERROR,
              );
              if (userFacingErrors.length > 0) {
                setUserFacingError(userFacingErrors[0]);
              }
              setAccessTokenResult(false);
              return;
            }

            setAccessTokenResult(true);
            const events = new BroadcastChannel<OAuthCallbackBroadcastMessage>(
              broadcastChannel,
            );
            await events.postMessage({ success: { linkedDataConnectionIds } });
            await events.close();
            window.close();
          })
          .catch((e) => {
            const userFacingErrors = getErrorMessagesWithCode(
              e,
              GraphQLErrorCode.USER_FACING_ERROR,
            );
            if (userFacingErrors.length > 0) {
              setUserFacingError(userFacingErrors[0]);
            }
            setAccessTokenResult(false);
            console.error(e);
          });
      }
    }, [broadcastChannel, code, state, fetchAccessToken, setUserFacingError]);

    useEffect(() => {
      void finishAuth();
    }, [finishAuth]);

    let resultString: string;
    if (accessTokenResult == null) {
      resultString = "OAuth handshake in progress";
    } else if (accessTokenResult) {
      resultString = "OAuth handshake completed successfully";
    } else {
      resultString = "OAuth handshake failed";
      if (userFacingError) {
        resultString += `: ${userFacingError}`;
      }
    }
    return <Container>{resultString}</Container>;
  });
